<template lang="pug">
    div
        StripeCheckout(
            ref="checkoutRef"
            mode="subscription"
            :pk="publishableKey"
            :session-id="sessionId"
            @loading="v => loading = v"
        )
</template>

<script>
import { StripeCheckout } from '@vue-stripe/vue-stripe'
import { computed, onMounted, ref, watch } from '@vue/composition-api'
import { STRIPE_PUBLISHABLE_KEY } from '@/common/constants'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { SignupSessionService } from '@/services/signup-session.service'
import { StripeApiService } from '@/services/stripe-api.service'
import { SubscriptionType } from '@/common/enums/subscription.type'
import { useSignupSessionStore } from '@/stores/useSignupSessionStore'
import { storeToRefs } from 'pinia'

const { useGetters, useActions } = createNamespacedHelpers('')
const signupSessionService = new SignupSessionService()
const stripeApiService = new StripeApiService()

export default {
    name: 'StripeCheckoutWrapper',
    components: { StripeCheckout },
    props: {
        submitSubscription: {
            type: Boolean,
            default: false
        },
        previousLineItems: {
            type: Array,
            default: () => []
        },
        signupSessionId: {
            type: String,
            default: ''
        },
        upgrade: {
            type: Boolean,
            default: false
        },
        subscriptionId: {
            type: String,
            default: ''
        }
    },
    emits: ['finished-upgrade'],
    setup(props, { emit }) {
        const rawSuccessUrl = `${location.origin}/signup-success`
        const rawCancelUrl = `${location.origin}/signup-cancel`
        const publishableKey = ref(STRIPE_PUBLISHABLE_KEY)
        const checkoutRef = ref({})
        const sessionId = ref('')
        const loading = ref(false)
        const successUrl = ref(rawSuccessUrl)
        const cancelUrl = ref(rawCancelUrl)
        const signupSessionStore = useSignupSessionStore()
        const { signupSessionSub, computedSignupForm } = storeToRefs(signupSessionStore)
        const { updateSignupSessionLoading, updateSignupSessionErrorMessage } = signupSessionStore
        const { selectedHardware, tenantId } = useGetters(['selectedHardware', 'tenantId'])
        const email = computed(() => computedSignupForm.value?.email)
        const submitSubscription = computed(() => props.submitSubscription)
        const signupSessionId = computed(() => props.signupSessionId)
        const isTrial = ref(false)
        const subscriptionType = ref('')

        const localLineItems = ref( [{
            price: '',
            quantity: 1
        }])

        const submit = async () => {
            //firstly we go and create the save of the state
            try {
                let newLineItems = JSON.parse(JSON.stringify(localLineItems.value))
                if (subscriptionType.value === SubscriptionType.LitePlan) newLineItems = [newLineItems.at(0)]
                const payload = {
                    form: { ...computedSignupForm.value },
                    lineItems: newLineItems,
                    subscriptionType: subscriptionType.value
                }
                let signupSession
                if (signupSessionId.value) signupSession = await signupSessionService.updateSignupSession(signupSessionId.value, payload)
                else signupSession = await signupSessionService.createSignupSession(payload)

                //now we assume we have the session so we set the urls for success and cancel
                successUrl.value = `${rawSuccessUrl}?sid=${signupSession?.signupSessionId}`
                cancelUrl.value = `${rawCancelUrl}?sid=${signupSession?.signupSessionId}`
                // if (!lineItems.value?.at(0).priceId) return
                // now we create the session
                const session = await stripeApiService.createSession(email.value, successUrl.value, cancelUrl.value, newLineItems, isTrial.value)
                sessionId.value = session.id
                checkoutRef.value.redirectToCheckout()
                updateSignupSessionErrorMessage('')
            } catch (e) {
                // need to set the error message and stop the loader
                updateSignupSessionErrorMessage('Email already in use')
            } finally {
                updateSignupSessionLoading(false)
            }
        }

        const submitUpdate = async () => {
            if (!signupSessionId.value) {
                // TODO: show an error since if we don't have a signupSessionId, something is very wrong
                return
            }

            const newPriceId = localLineItems.value.at(0).price
            const subscription = await stripeApiService.upgradeSubscription(props.subscriptionId, newPriceId, tenantId.value, subscriptionType.value)
            // todo: reload the signupSession, etc
            emit('finished-upgrade')
        }

        onMounted(() => {
            if (props.previousLineItems.length) localLineItems.value = [...props.previousLineItems]
        })

        watch(submitSubscription, (newValue) => {
            if (newValue && !props.upgrade) submit()
            else submitUpdate()
        })

        watch(() => props.previousLineItems, (newValue) => {
            localLineItems.value = [...newValue]
        })

        watch([signupSessionSub, selectedHardware], ([subscription, hardware]) => {
            subscriptionType.value = subscription?.option
            isTrial.value = subscription?.option === SubscriptionType.FreeTrial

            if (!localLineItems.value.length) return

            let temp = JSON.parse(JSON.stringify(localLineItems.value))
            temp[0].price = subscription.priceId

            // if we have some kind of hardware option, we add it to the list if none already exists, otherwise we change it
            if (Object.keys(hardware).length > 0) {
                if (temp.length < 2) temp.push({ ...hardware })
                else temp[1] = {...hardware}
            } else {
                // if the user selects the 'no Hardware' option, we remove it ot leave the list as it is
                if (temp.length > 1) temp = [temp.at(0)]
            }
            localLineItems.value = [...temp]
        }, { immediate: true })

        return {
            sessionId,
            publishableKey,
            checkoutRef,
            loading,
            successUrl,
            cancelUrl,
            localLineItems
        }
    }
}
</script>
