<template lang="pug">
    div
        div(
            v-if="showNoHardwareOption"
            class="grid grid-cols-12 flex my-6 cursor-pointer rounded relative"
            :class="getActiveClass()"
            @click="updateSelectedHardware({})"
        )
                div(class="col-span-12 p-4 text-left box-bg rounded-r h-full")
                    div(class="flex items-center justify-between")
                        h4 No Hardware
                        div.text-xl.text-white.font-semibold
                            | $0
        div(v-for="option in hardware" :class="getActiveClass(option)")
            HardwareOption(:hardware="option" :add-more="showAddMoreNum" @select-hardware-line-item="updateSelectedHardware")
</template>

<script>
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import HardwareOption from '@/components/subscription/partials/HardwareOption.vue'
import { ref, watch } from '@vue/composition-api'
import { NO_HARDWARE_OBJ } from '@/common/constants'
import { useSignupSessionStore } from '@/stores/useSignupSessionStore'
import { storeToRefs } from 'pinia'

const { useGetters, useActions } = createNamespacedHelpers('')

export default {
    name: 'HardwareOptions',
    components: { HardwareOption },
    props: {
        previousLineItems: {
            type: Array,
            default: () => []
        },
        showNoHardware: {
            type: Boolean,
            default: true
        },
        addMore: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const signupSessionStore = useSignupSessionStore()
        const { updateSignupSessionLineItems } = signupSessionStore
        const { signupSessionLineItems: lineItems } = storeToRefs(signupSessionStore)
        const { hardware, selectedHardware } = useGetters(['hardware', 'selectedHardware'])
        const { setSelectedHardware } = useActions(['setSelectedHardware'])
        const showNoHardwareOption = ref(props.showNoHardware)
        const showAddMoreNum = ref(props.addMore)

        const getActiveClass = (hardwareOption) => {
            if (showAddMoreNum.value) return ''
            if (!hardwareOption && !Object.keys(selectedHardware.value)) return 'selectedHardware'
            if (selectedHardware.value?.price === hardwareOption?.price?.id) return 'selectedHardware'
            return ''
        }

        // default set hardwareLineItems
        const updateDefaultHardware = () => {
            const temp = [...lineItems.value]
            temp.push({...NO_HARDWARE_OBJ})

            updateSignupSessionLineItems([...temp])
        }

        const updateSelectedHardware = (newSelectedHardware) => {
            //if we are adding more hardware, we don't do the below, we need to do something else
            if (showAddMoreNum.value) return

            let temp = [...lineItems.value]

            const hardwareOption = newSelectedHardware?.price ? {price: newSelectedHardware.price.id, quantity: 1} : {}
            setSelectedHardware(hardwareOption)

            if (temp.length < 2) temp.push(newSelectedHardware)
            else temp[1] = { ...newSelectedHardware }
            if (!temp.at(1)) temp.push({ ...NO_HARDWARE_OBJ })
            else if (!Object.keys(temp.at(1)).length) temp[1] = NO_HARDWARE_OBJ

            updateSignupSessionLineItems([...temp])
        }

        // let's only push the default or the current if the subscription has finished
        watch(lineItems, (newValue) => {
            if (newValue.length < 2) updateDefaultHardware()
        })

        watch([() => props.previousLineItems, hardware], ([previousLineItems, hardwareList]) => {
            if (!hardwareList.length) return
            // hardware should always be the second object in the array (for now)
            const previousHardwareOption = previousLineItems.at(1)
            if (!previousHardwareOption) return
            setSelectedHardware(previousHardwareOption)
            const foundHardware = hardwareList.find(h => h.price.id === previousHardwareOption.price)
            if (foundHardware) updateSelectedHardware(foundHardware)
        })

        return { hardware, showNoHardwareOption, showAddMoreNum, updateSelectedHardware, getActiveClass }
    }
}
</script>
