import { computed, ref, watch } from '@vue/composition-api'
import { SignupSessionService } from '@/services/signup-session.service'
import { MAX_SHORTNAME_LENGTH } from '@/common/constants'
import { useSignupSessionStore } from '@/stores/useSignupSessionStore'
import { storeToRefs } from 'pinia'
import { getRandomNum } from '@/modules/utilFns'

const signupSessionService = new SignupSessionService()

export const useSubscriptionOptions = (variables) => {
    const manuallySetShortName = ref(false)
    const signupSessionStore = useSignupSessionStore()
    const { updateSignupForm, updateSignupSessionLoading, updateSignupSessionErrorMessage } = signupSessionStore
    const { signupSessionSub, signupSessionLineItems: lineItems } = storeToRefs(signupSessionStore)
    const signupForm = ref({
        email: '',
        firstName: '',
        lastName: '',
        practiceName: '',
        shortName: '',
        practiceType: '',
        subscribeToNewsletter: false,
        authorisedToActOnBehalfOf: false,
        acceptedTermsAndConditions: false,
        surveyToolsFeatureAvailable: false
    })
    const alertMessage = ref('')
    const subscriptionOption = ref(null)
    const creatingAccount = ref(false)
    const showAlert = ref(false)
    const isValid = ref(true)
    const form = ref({})
    const priceId = ref('')
    const submitToStripe = ref(false)
    const previousLineItems = ref([])
    const signupSessionId = ref(variables?.signupSessionId || '')

    const btnText = computed(() =>
        creatingAccount.value ? 'Please wait...' : 'Go to Payment'
    )

    const changePlanBtnText = computed(() =>
        creatingAccount.value ? 'Please wait...' : 'Upgrade'
    )

    const validate = () => {
        isValid.value = form.value.validate()
        return isValid.value
    }

    const priceHasChanged = ({ priceId: newPriceId, option }) => {
        priceId.value = newPriceId
        subscriptionOption.value = option
    }

    const formSubmitHandler = () => {
        alertMessage.value = ''
        showAlert.value = false
        updateSignupSessionErrorMessage('')
        creatingAccount.value = true
        showAlert.value = false
        validate()
        if (!isValid.value) {
            creatingAccount.value = false
            return
        }
        updateSignupSessionLoading(true)
        submitToStripe.value = true
    }

    watch(() => signupForm.value?.practiceName, (updatedPracticeName) => {
        // first we change to lower case and remove any special characters (except for spaces)
        const nameSplit = updatedPracticeName?.toLocaleLowerCase()?.replace(/[^\w ]/g, '')?.split(' ')
        let suffix = nameSplit.length <= 1 ? '' : nameSplit.slice(1).reduce((curr, n) => `${curr}${n.slice(0, 1)}`, '')
        if (!signupForm.value.shortName && !manuallySetShortName.value) {
            const newShortName = `${nameSplit.at(0)}${suffix}-${getRandomNum()}`
            // signupForm.value.shortName = updatedPracticeName.toLowerCase().replace(/ /g, '-').slice(0, MAX_SHORTNAME_LENGTH)
            signupForm.value.shortName = newShortName
        }
    })

    watch(signupForm, (newValue) => {
        updateSignupForm({ ...newValue })
    }, {deep: true})

    watch(signupSessionId, async (newValue) => {
        if (!newValue) return
        const foundSignupSession = await signupSessionService.getSignupSession(newValue)
        if (!foundSignupSession) return
        updateSignupForm({ ...(foundSignupSession?.content?.form || {}) })
        signupForm.value = { ...(foundSignupSession?.content?.form || {}) }
        previousLineItems.value = [...(foundSignupSession?.content?.lineItems || [])]
    }, { immediate: true })

    // @deprecated: We should get the prices from the store
    watch(
        signupSessionSub,
        (newValue) => {
            priceHasChanged(newValue || {})
        },
        { deep: true }
    )

    return {
        subscriptionOption,
        alertMessage,
        signupForm,
        previousLineItems,
        signupSessionId,
        submitToStripe,
        priceId,
        form,
        isValid,
        creatingAccount,
        lineItems,
        btnText,
        changePlanBtnText,
        showAlert,
        manuallySetShortName,
        formSubmitHandler,
        priceHasChanged
    }
}
