<template lang="pug">
    .flex.flex-row.items-center
        div(
            class="grid grid-cols-12 flex my-6 cursor-pointer rounded relative"
            @click="selectOption"
        )
            div(class="col-span-12 md:col-span-3")
                img(:src="displayImage" class="w-full h-auto rounded-l")
                //img(src="@/assets/images/hardware/floorstand.png" class="w-full h-auto")
            div(class="col-span-12 md:col-span-9")
                div(class="p-4 text-left box-bg rounded-r h-full")
                    div(class="flex items-center justify-between")
                        .flex-col.items-center
                            h4 {{ hardware.name }}
                        div.text-xl.text-white.font-semibold
                            | ${{ getUnitPrice(hardware) }}
                    div.text-sm.mt-4 {{ hardware.description }}
        div(v-if="showAddMoreNum" class="ml-4")
            div.text-xs.font-bold.font-display.text-grey-2.uppercase Quantity
            v-text-field(
                filled
                dense
                class="w-80"
                v-model="quantity"
            )
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api'
import FloorStandImage from '@/assets/images/hardware/floorstand.png'
import ScrewMountImage from '@/assets/images/hardware/screwmount.png'
import FreeStandingImage from '@/assets/images/hardware/freestanding.png'
import { useAddHardwareStore } from '@/stores/useAddHardwareStore'
import { storeToRefs } from 'pinia'

export default {
    name: 'HardwareOption',
    props: {
        hardware: {
            type: Object,
            required: true
        },
        addMore: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {
        const showAddMoreNum = ref(props.addMore)
        const quantity = ref(0)
        const addHardwareStore = useAddHardwareStore()
        const { computedHardware: storeHardware } = storeToRefs(addHardwareStore)
        const { updateHardware } = addHardwareStore

        const displayImage = computed(() => {
            if (props.hardware?.images?.length > 0) return props.hardware.images.at(0)
            const hardwareName = props.hardware.name.toLowerCase()
            if (hardwareName.indexOf('floorstand') > -1) return FloorStandImage
            if (hardwareName.indexOf('screwmount') > -1) return ScrewMountImage
            if (hardwareName.indexOf('freestanding') > -1) return FreeStandingImage
        })

        const getUnitPrice = (option) => option?.price?.unit_amount / 100 || ''

        const selectOption = () => {
            emit('select-hardware-line-item', props.hardware)
        }

        // we don't set this as immediate since we need to know if we are on the 'Purchase hardware page or not
        // if we are on signup, this won't change... only changes if we are on the 'purchase more hardware' page
        watch(quantity, (newQuantity) => {
            // first we check if we already have the quantity in the store
            const temp = [...storeHardware.value]
            const idx = temp.findIndex((t) => t.price === props.hardware.price?.id)
            if (idx > -1) temp[idx].quantity = newQuantity
            else temp.push({ price: props.hardware.price?.id, quantity: newQuantity })
            updateHardware([...temp])
        })

        return { displayImage, showAddMoreNum, quantity, getUnitPrice, selectOption }
    }
}
</script>
