import { defineStore } from 'pinia'
import { computed, ref } from '@vue/composition-api'

export const useAddHardwareStore = defineStore('add-hardware', () => {
    const hardware = ref([])
    const submitHardwareToStripe = ref(false)
    const addHardwareAlertMessage = ref('')
    const addHardwareAlertType = ref('')
    const showAddHardwareAlert = ref(false)

    const computedHardware = computed(() => hardware.value)
    const computedSubmitHardwareToStripe = computed(() => submitHardwareToStripe.value)
    const computedAddHardwareAlertMessage = computed(() => addHardwareAlertMessage.value)
    const computedAddHardwareAlertType = computed(() => addHardwareAlertType.value)
    const computedShowAddHardwareAlert = computed(() => showAddHardwareAlert.value)

    // the param newHardware is a list of price ids and quantities
    const updateHardware = (newHardware) => {
        hardware.value = [...newHardware]
    }

    const updateSubmitHardwareToStripe = (newSubmitHardwareToStripe) => {
        submitHardwareToStripe.value = newSubmitHardwareToStripe
    }

    const updateAddHardwareAlertMessage = (newAddHardwareAlertMessage) => {
        addHardwareAlertMessage.value = newAddHardwareAlertMessage
    }

    const updateAddHardwareAlertType = (newAddHardwareAlertType) => {
        addHardwareAlertType.value = newAddHardwareAlertType
    }

    const updateShowAddHardwareAlert = (newShowAddHardwareAlert) => {
        showAddHardwareAlert.value = newShowAddHardwareAlert
    }

    return {
        computedHardware,
        computedAddHardwareAlertMessage,
        computedShowAddHardwareAlert,
        computedAddHardwareAlertType,
        computedSubmitHardwareToStripe,
        updateHardware,
        updateSubmitHardwareToStripe,
        updateAddHardwareAlertMessage,
        updateAddHardwareAlertType,
        updateShowAddHardwareAlert
    }
})
