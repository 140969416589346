/**
 * @description
 * Takes an Array<V>, and a grouping function,
 * and returns a Map of the array grouped by the grouping function.
 *
 * @param list An array of type V.
 * @param keyGetter A Function that takes the Array type V as an input, and returns a value of type K.
 *                  K is generally intended to be a property key of V.
 *
 * @returns Map of the array grouped by the grouping function.
 */
//export function groupBy<K, V>(list: Array<V>, keyGetter: (input: V) => K): Map<K, Array<V>> {
//    const map = new Map<K, Array<V>>();
const groupBy = (list, keyGetter) => {
    const map = new Map()
    for (const item of list) {
        const key = keyGetter(item)
        const collection = map.get(key)

        if (!collection) map.set(key, [item])
        else collection.push(item)
    }
    return map
}

const getFilenameFromContentDisposition = (response) => {
    let filename = ''
    const disposition = response?.headers['content-disposition']
    if (!disposition) return filename

    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    const matches = filenameRegex.exec(disposition)
    if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '')
    }

    return filename
}

const getRandomNum = () => {
    return Math.round(Math.random() * 1000)
}

export {
    getRandomNum,
    groupBy,
    getFilenameFromContentDisposition
}
